// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-tsx": () => import("/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-templates-author-tsx": () => import("/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-pages-404-tsx": () => import("/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-license-tsx": () => import("/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/src/pages/license.tsx" /* webpackChunkName: "component---src-pages-license-tsx" */),
  "component---src-pages-tagpage-tsx": () => import("/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/src/pages/tagpage.tsx" /* webpackChunkName: "component---src-pages-tagpage-tsx" */)
}


module.exports = [{
      plugin: require('/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1170,"quality":90},
    },{
      plugin: require('/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://amlanscloud.com"},
    },{
      plugin: require('/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-165385084-1","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"],"sampleRate":100,"siteSpeedSampleRate":10},
    },{
      plugin: require('/__w/myblogcodebase-gatsby-casper/myblogcodebase-gatsby-casper/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-MQV13KKDQW"],"pluginConfig":{"head":true}},
    }]
